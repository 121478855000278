import * as React from "react";
import type { HeadFC } from "gatsby";
import styled from "styled-components";

import { Container } from "../components/Grid";
import { Layout } from "../components/Layout";

const ScMain = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #020203;

  .post-full-content {
    background-color: transparent;
  }
`;

const LegalPage = () => {
  return (
    <Layout>
      <ScMain>
        <Container>
          <article className="post-full post no-image page no-image">
            <h1>API License Agreement</h1>
            <section className="post-full-content">
              <div className="post-content">
                <p>
                  <br />
                  This Application Programming Interfaces License Agreement
                  (this "<strong>Agreement</strong>") is a legal and binding
                  contract between Power Fintech LLC, a Saint Vincent and the
                  Grenadines limited liability company with Limited Liability
                  Company Number 711 LLC 2020, its successors and assigns
                  (hereinafter "<strong>PowerTrade</strong>", “
                  <strong>our</strong>”, “<strong>us</strong>” or “
                  <strong>we</strong>”) and the party or parties using the API
                  (the "<strong>Licensee</strong>", “<strong>Customer</strong>”
                  or “<strong>you</strong>”). By installing, downloading,
                  copying, or otherwise using the Application Programming
                  Interface (“<strong>API</strong>”), you agree to be and will
                  be bound by the terms of this Agreement as a condition of your
                  license. If you do not agree to the terms of this Agreement,
                  you shall not use the API.
                </p>
                <p>
                  The terms used in this Agreement shall have the same meanings
                  as in the{" "}
                  <a href="https://power.trade/terms/">Terms of Service</a>{" "}
                  except as otherwise provided herein.
                  <br />
                </p>
                <ol>
                  <li>
                    <strong>
                      <strong>
                        <strong>DEFINITIONS AND INTERPRETATION</strong>
                      </strong>
                    </strong>
                  </li>
                </ol>
                <p>
                  <strong>1.1. Definitions</strong>
                </p>
                <p>
                  “<strong>Application</strong>” shall mean the software
                  applications or web applications used or developed by the
                  Licensee in connection with the API.
                </p>
                <p>
                  "<strong>Application Programming Interface</strong>" or "
                  <strong>API</strong>" shall mean PowerTrade’s application
                  programming interfaces and their associated tools including
                  but not limited to object code, software libraries, software
                  tools, sample source code, published specifications, software
                  development kits (“SDKs”) and the Documentation.
                </p>
                <p>
                  "<strong>Documentation</strong>" shall include, without
                  limitation, programmer guides, CDs, manuals, materials, and
                  information appropriate or necessary for use in connection
                  with the API.
                </p>
                <p>
                  “<strong>Third Party Software</strong>” shall include, without
                  limitation, development tools, compilers and other software
                  and technology of third parties.
                </p>
                <p></p>
                <p>
                  <strong>1.2. Construction. </strong>In this Agreement, unless
                  the context otherwise requires
                </p>
                <p>
                  a). words importing the singular include the plural and vice
                  versa;
                </p>
                <p>
                  b). words importing a gender include both gender and the
                  neuter;
                </p>
                <p>
                  c). words importing persons include companies, associations
                  and bodies of persons whether corporate or not;
                </p>
                <p>
                  d). the words: “may” shall be construed as permissive; “shall”
                  or “will” shall be construed as imperative;
                </p>
                <p>
                  e). the term “include” or “includes” means includes, without
                  limitation, and “including” means including, without
                  limitation;
                </p>
                <p>
                  f). the terms “hereof”, “herein” and “hereunder” refer to the
                  Terms as a whole and not to any particular provision of the
                  Terms; and
                </p>
                <p>
                  g). the headings contained in these Terms are for reference
                  purposes only, and shall not affect in any way the meaning or
                  interpretation of the Terms.
                  <br />
                </p>
                <p>
                  <strong>
                    2.{" "}
                    <strong>
                      <strong>LICENSE</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>2.1. Grant of License.</strong> Subject to the terms
                  of this Agreement, PowerTrade hereby grants the Licensee a
                  limited, non-exclusive, non-sublicensable, non-transferable,
                  non-assignable, royalty-free license under PowerTrade’s
                  intellectual property rights during the term of this Agreement
                  (i) to use the API solely for the purpose of Licensee’s
                  internal development efforts to develop Applications to work
                  in conjunction with the Services referenced in the API; (ii)
                  to distribute or allow access to Licensee’s integration of the
                  APIs within the Application to end users of the Application;
                  and (iii) to display the data received from the APIs within
                  the Application.
                </p>
                <p>
                  <strong>2.2. Revocation of License</strong>. We may revoke
                  this license at any time for any reason in our sole
                  discretion. We may also limit or suspend API calls from your
                  Application if we determine in our sole discretion the number
                  of API calls to the Services is excessive or you are violating
                  this Agreement.{" "}
                </p>
                <p>
                  <strong>2.3. License Restrictions</strong>. You shall not:
                </p>
                <ol>
                  <li>
                    disclose or provide the APIs to any person or entity other
                    than to Licensee’s employees or independent contractors who
                    are individuals, provided (i) such persons enter into an
                    agreement with Licensee at least as protective of
                    PowerTrade’s rights as this Agreement, and (ii) Licensee
                    hereby agree to be responsible for, and liable to PowerTrade
                    for, any breaches of such agreements by Licensee’s
                    independent contractors;
                  </li>
                  <li>
                    use the APIs for any illegal, unauthorized or otherwise
                    improper purposes, or in any manner which would violate this
                    Agreement or the Documentation, or breach any laws or
                    regulations, or violate the rights of third parties;
                  </li>
                  <li>
                    remove any legal, copyright, trademark or other proprietary
                    rights notices contained in or on materials Licensee receive
                    or access pursuant to this Agreement, including but not
                    limited to, the APIs and the Documentation;
                  </li>
                  <li>
                    reproduce, distribute, license (whether or not through
                    multiple tiers), transfer or otherwise provide or make the
                    API available to any third party;
                  </li>
                  <li>
                    introduce into the Services any viruses, worms, Trojan
                    horses, backdoors, or other malicious code through the
                    Application or otherwise;
                  </li>
                  <li>
                    interfere with or disrupt the Services, or servers or
                    networks connected to the Services, or disobey any
                    requirements, procedures, policies or regulations of
                    networks connected to the Services;
                  </li>
                  <li>
                    use the APIs in a manner that, as determined by PowerTrade
                    in its sole discretion, exceeds reasonable request volume,
                    constitutes excessive or abusive usage, or otherwise fails
                    to comply or is inconsistent with any part of the
                    Documentation;
                  </li>
                  <li>
                    attempt to derive the source code or object code for the
                    Services or any component thereof, including by reverse
                    engineering, decompiling, disassembling, or similar means;
                  </li>
                  <li>
                    use your Application to interfere with or attempt to
                    interfere with or disrupt the integrity, security,
                    functionality, or proper working of the Services; or
                  </li>
                  <li>
                    access and/or use the Services and API in order to build a
                    similar or competitive product.
                  </li>
                </ol>
                <p>
                  <strong>2.4. Compliance</strong>. You shall comply with the
                  Terms of Service, Privacy Policy, Margin Facility Agreement,
                  and all laws that apply to you with respect to your use of the
                  API or the Services.
                  <br />
                </p>
                <p>
                  <strong>
                    3.{" "}
                    <strong>
                      <strong>OTHER RIGHTS AND LIMITATIONS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>3.1. Copies.</strong> You may copy the API only as
                  necessary to exercise your rights hereunder.
                </p>
                <p>
                  <strong>3.2. No Reverse Engineering.</strong> You shall have
                  no right to any source code for any of the software in the
                  API, except for the explicit rights to use the source code as
                  provided to you hereunder. You may not reverse engineer,
                  decompile, modify, disassemble or otherwise alter the API or
                  any part thereof or otherwise reduce the API to
                  human-perceivable form in whole or in part, except and only to
                  the extent that such activity is expressly permitted by this
                  Agreement or applicable laws.
                </p>
                <p>
                  <strong>3.3. Third Party Software</strong>. You acknowledge
                  that effective utilisation of the API may require the use of
                  Third Party Software. You are solely responsible for procuring
                  such Third Party Software and technology and the necessary
                  licenses for the use thereof. We make no representation or
                  warranty concerning Third Party Software and we shall have no
                  obligation or liability with respect to Third Party Software.
                </p>
                <p>
                  <strong>
                    3.4. No right is granted to Licensee to sublicense its
                    rights hereunder.
                  </strong>{" "}
                  All rights not expressly granted are reserved by PowerTrade
                  and, except as expressly set forth herein, no license is
                  granted by PowerTrade under this Agreement directly, by
                  implication, estoppel or otherwise, under any patent,
                  copyright, trade secret or trademark or other intellectual
                  property rights of PowerTrade. Nothing herein shall be deemed
                  to authorize Licensee to use PowerTrade’s trademarks or trade
                  names in Licensee’s Application, advertising, marketing,
                  promotional, sales or related materials. PowerTrade reserves
                  all rights not otherwise expressly granted in this Agreement.
                </p>
                <p>
                  <strong>3.5. No assertion by Licensee.</strong> Licensee
                  agrees not to assert any patent rights or other intellectual
                  property rights related to the API or applications developed
                  using the API against PowerTrade, PowerTrade Entities,
                  Powertrade’s Affiliated Entities, distributors, or other
                  licensees of the API for making, using, selling, offering for
                  sale, or importing any products or technology developed using
                  the API.
                  <br />
                </p>
                <p>
                  <strong>
                    4.{" "}
                    <strong>
                      <strong>INTELLECTUAL PROPERTY</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>4.1. Ownership.</strong> PowerTrade or its licensors
                  shall own and retain all proprietary rights, including all
                  patent, copyright, trade secret, trademark and other
                  intellectual property rights, in and to the API and any
                  corrections, bug fixes, enhancements, updates, improvements,
                  or modifications thereto and Licensee hereby irrevocably
                  transfers, conveys and assigns to PowerTrade all of its right,
                  title, and interest therein. PowerTrade shall have the
                  exclusive right to apply for or register any patents, mask
                  work rights, copyrights, and such other proprietary
                  protections with respect thereto. Licensee acknowledges that
                  the license granted under this Agreement does not provide
                  Licensee with title or ownership to the API, but only a right
                  of limited use under the terms and conditions of this
                  Agreement.
                  <br />
                </p>
                <p>
                  <strong>
                    5.{" "}
                    <strong>
                      <strong>SUPPORT</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  Licensee agrees to report to PowerTrade any errors or
                  difficulties discovered and the characteristic conditions and
                  symptoms of such errors and difficulties. PowerTrade is in no
                  way obligated to provide Licensee with any error correction or
                  support, but may provide whatever error correction and/or
                  support services PowerTrade may determine in its sole
                  discretion (and anything it provides in connection therewith
                  will be deemed part of the API). Nothing herein shall be
                  construed to require PowerTrade to provide support services or
                  updates, upgrades, bug fixes or modifications to the API.
                </p>
                <p></p>
                <p>
                  <strong>
                    6.{" "}
                    <strong>
                      <strong>CONFIDENTIALITY</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  The API (including, without limitation, all improvement,
                  derivatives, modifications and the like) constitutes
                  PowerTrade’s confidential information (“
                  <strong>Confidential Information</strong>”). Licensee hereby
                  agrees (i) to hold the Confidential Information in confidence
                  and to take reasonable precautions to protect such
                  Confidential Information (including, without limitation, all
                  precautions the Licensee employs with respect to its own
                  confidential materials), (ii) not to divulge any Confidential
                  Information to any third person (except consultants, subject
                  to the conditions stated below), (iii) not to use any
                  Confidential Information except for the purposes set forth in
                  this Agreement, and (iv) not to copy or reverse engineer any
                  Confidential Information. Any employee or consultant given
                  access to the Confidential Information must have a legitimate
                  “need to know” and shall be similarly bound in writing.
                  Licensee acknowledges and agrees that due to the unique nature
                  of PowerTrade’s Confidential Information, there can be no
                  adequate remedy at law for any breach of its obligations
                  hereunder and therefore, that upon any such breach or any
                  threat thereof, PowerTrade shall be entitled to appropriate
                  equitable relief in addition to whatever remedies it might
                  have at law.
                </p>
                <p></p>
                <p>
                  <strong>
                    7.{" "}
                    <strong>
                      <strong>TERM</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>7.1. Commencement.</strong> The effective date of this
                  Agreement is the start of use of the API by the Licensee.
                </p>
                <p>
                  <strong>7.2. Non-Compliance</strong>. This Agreement shall
                  terminate automatically if the Licensee fails to comply with
                  any of the terms and conditions of this Agreement and the
                  Licensee shall be liable to PowerTrade, the PowerTrade
                  Entities and its suppliers for damages or losses caused by its
                  non-compliance.{" "}
                </p>
                <p>
                  <strong>7.3. Termination</strong>. Either party shall have the
                  right to terminate the Agreement at any time, for any reason,
                  without prior notice or liability.
                </p>
                <p>
                  <strong>7.4. Documentation and Copies.</strong> Upon
                  termination of this Agreement, Licensee will immediately cease
                  using the Documentation, and Licensee agrees to destroy all
                  adaptations or copies of the API and Documentation or return
                  them to PowerTrade upon termination of this License.
                </p>
                <p>
                  <strong>7.5. Audit.</strong> PowerTrade shall have the right
                  to audit the Licensee use of the API in conjunction with this
                  Agreement, and the Licensee shall provide reasonable
                  assistance for this purpose.
                </p>
                <p>
                  <strong>7.6. Survival.</strong> PowerTrade rights and Licensee
                  obligations contained in this Agreement survive any expiration
                  or termination of this Agreement.
                </p>
                <p></p>
                <p>
                  <strong>
                    8.{" "}
                    <strong>
                      <strong>DISCLAIMER OF WARRANTIES</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  The Licensee acknowledges that the API, and any services are
                  provided “AS IS” without warranty of any kind.{" "}
                </p>
                <p>
                  POWERTRADE AND ITS LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES
                  RELATING TO THE API OR THE SERVICES, EXPRESS OR IMPLIED,
                  INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES AGAINST
                  INFRINGEMENT OF THIRD-PARTY RIGHTS, SATISFACTORY QUALITY,
                  CORRESPONDENCE WITH DESCRIPTION, MERCHANTABILITY AND FITNESS
                  FOR A PARTICULAR PURPOSE. NO ADVICE OR INFORMATION, WHETHER
                  ORAL OR WRITTEN, OBTAINED BY YOU FROM POWERTRADE OR THROUGH OR
                  FROM THE API OR THE DOCUMENTATION SHALL CREATE ANY WARRANTY.
                  POWERTRADE DOES NOT WARRANT THAT THE API AND DOCUMENTATION ARE
                  SUITABLE FOR LICENSEE’S USE, THAT THE API OR DOCUMENTATION ARE
                  WITHOUT DEFECT OR ERROR, THAT OPERATION WILL BE UNINTERRUPTED,
                  OR THAT DEFECTS WILL BE CORRECTED. POWERTRADE MAKES NO
                  WARRANTY REGARDING THE RESULTS OF THE USE OF THE API AND
                  DOCUMENTATION. <br />
                </p>
                <p>
                  <strong>
                    9.{" "}
                    <strong>
                      <strong>INDEMNIFICATION</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  You agree to indemnify and hold PowerTrade, its subsidiaries,
                  affiliates, agents, employees, agents, successors and assigns
                  ("
                  <strong>PowerTrade Entities</strong>”) harmless from and
                  against any and all liabilities, losses, damages, costs and
                  expenses, including attorney’s fees, incurred by any of
                  PowerTrade Entities arising out of of your use of the API,
                  your connection to the API, or your violation of the
                  Agreement.
                </p>
                <p>
                  PowerTrade Entities reserve the right to exercise sole control
                  over the defense, at your expense, of any claim subject to
                  indemnification under this section. <br />
                </p>
                <p>
                  <strong>
                    10.{" "}
                    <strong>
                      <strong>LIMITATION OF LIABILITY</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU
                  EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT WILL
                  POWERTRADE ENTITIES AND THEIR RESPECTIVE SHAREHOLDERS,
                  MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
                  REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY
                  INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR
                  SIMILAR DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT
                  LIMITATION, DAMAGES FOR LOSS OF DATA, INFORMATION, REVENUE,
                  PROFITS OR OTHER BUSINESSES OR FINANCIAL BENEFITS) ARISING OUT
                  OF OR RELATING TO THE USE OR THE INABILITY TO USE THE API, ANY
                  PERFORMANCE OR NON-PERFORMANCE OF THE API OR THE SERVICES,
                  APPLICATION, ANY THIRD PARTY SOFTWARE, ITS CONTENT OR
                  FUNCTIONALITY, WHETHER UNDER CONTRACT, STATUTE, STRICT
                  LIABILITY OR OTHER THEORY EVEN IF THE POWERTRADE ENTITIES HAVE
                  BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p>
                  IF YOU ARE DISSATISFIED WITH THE API, THE DOCUMENTATION, ANY
                  PORTION OF THE SERVICES OR WITH THIS AGREEMENT, YOUR SOLE AND
                  EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE API AND/OR THE
                  SERVICES. <br />
                </p>
                <p>
                  <strong>
                    11.{" "}
                    <strong>
                      <strong>NO WAIVER</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  From time to time, PowerTrade may fail to require or strictly
                  enforce compliance with relation to any provision in this
                  Agreement. PowerTrade may also fail to exercise any or all of
                  its rights empowered herein. Any such failure shall not be
                  construed as a waiver or relinquishment of PowerTrade's right
                  to assert or rely upon any such provision or right in that or
                  in any other instance. If applicable, an express waiver given
                  by PowerTrade of any condition, provision, or requirement of
                  this Agreement shall not constitute a waiver of any future
                  obligation to comply with such condition, provision or
                  requirement. <br />
                </p>
                <p>
                  <strong>
                    12.{" "}
                    <strong>
                      <strong>GOVERNING LAW AND JURISDICTION</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  This Agreement shall be interpreted by English law without
                  giving effect to any conflict of laws principles that may
                  provide for the application of the law of another
                  jurisdiction.
                </p>
                <p>
                  You agree to submit any Dispute (as defined below) to
                  arbitration in accordance with the terms of Section 16. To the
                  extent that the agreement to arbitrate is ineffective or void,
                  you agree to submit to the exclusive jurisdiction of the
                  courts of Saint Vincent and the Grenadines.
                  <br />
                </p>
                <p>
                  <strong>
                    13.{" "}
                    <strong>
                      <strong>SUBMISSION TO ARBITRATION</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  Any dispute, claim, suit, action, cause of action, demand, or
                  proceeding arising out of or related to this Agreement (any
                  "Dispute") that is not settled by you and PowerTrade within 30
                  days from the date that either party notifies the other party
                  in writing of the Dispute shall be referred to and finally
                  settled by arbitration. Further, the parties hereby agree
                  that:
                </p>
                <p>
                  a). To attempt informal resolution prior to any demand for
                  arbitration for at least 30 days before initiating any
                  arbitration or court proceeding. Such informal negotiations
                  commence upon receipt of written notice from you. If we cannot
                  resolve the dispute on an informal basis, you and we agree
                  that any dispute arising under this Agreement shall be finally
                  settled in binding arbitration, on an individual basis;
                </p>
                <p>
                  b). That any dispute, controversy or claim arising out of or
                  relating to the Terms, or the breach, termination or
                  invalidity thereof, shall be settled by arbitration in
                  accordance with the Singapore International Arbitration Centre
                  (“SIAC”) rules;
                </p>
                <p>c). That the number of arbitrators shall be one;</p>
                <p>
                  d). That the place of arbitration shall be the SIAC, unless
                  the Parties agree otherwise;
                </p>
                <p>
                  e). That the language to be used in the arbitral proceedings
                  shall be English;
                </p>
                <p>
                  f). That the courts in Saint Vincent and the Grenadines have
                  non-exclusive jurisdiction over any appeals of an arbitration
                  award and over any suit between the parties not subject to
                  arbitration;
                </p>
                <p>
                  g). That the arbitrator has the authority to grant any remedy
                  that would otherwise be available in court; and
                </p>
                <p>
                  h). That the parties shall split the costs and expenses of any
                  arbitration and bear their own legal costs and expenses.
                  <br />
                </p>
                <p>
                  <strong>
                    14.{" "}
                    <strong>
                      <strong>
                        WAIVER OF CLASS ACTION RIGHTS AND CLASS WIDE ARBITRATION
                      </strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  You and PowerTrade agree that any claims relating to this
                  Agreement or to your relationship with PowerTrade as a
                  Licensee of the API (whether based in contract, tort, statute,
                  fraud, misrepresentation, or any other legal theory, and
                  whether the claims arise during or after the termination of
                  this Agreement) shall be brought against the other party in an
                  arbitration on an individual basis only and not as a plaintiff
                  or class member in a purported class or representative action.
                  You and PowerTrade further agree to waive any right for such
                  claims to be brought, heard, or arbitrated as a class,
                  collective, representative, or private attorney general
                  action, to the extent permissible by applicable law. You agree
                  not to join with any other individual or entity or group of
                  individuals or entities for the purpose of seeking to resolve
                  the respective Disputes on a consolidated or representative
                  basis.
                  <br />
                </p>
                <p>
                  <strong>
                    15.{" "}
                    <strong>
                      <strong>MISCELLANEOUS</strong>
                    </strong>
                  </strong>
                </p>
                <p>
                  <strong>15.1. Assignment</strong>.<strong> </strong>You may
                  not assign any rights and/or licenses granted under this
                  Agreement. We reserve the right to assign our rights without
                  restriction, including without limitation to any of our
                  affiliates or subsidiaries, or to any successor in interest of
                  any business associated with the Services. Any attempted
                  transfer or assignment in violation hereof shall be null and
                  void. Subject to the foregoing, this Agreement will bind and
                  inure to the benefit of the parties, their successors and
                  permitted assigns.
                </p>
                <p>
                  <strong>15.2. Severability</strong>. If any provision of this
                  Agreement shall be determined to be invalid or unenforceable
                  under any rule, law or regulation or any governmental agency,
                  local, state, or federal, such provision will be changed and
                  interpreted to accomplish the objectives of the provision to
                  the greatest extent possible under any applicable law and the
                  validity or enforceability of any other provision of the Terms
                  shall not be affected.
                </p>
                <p>
                  <strong>15.3. Force Majeure.</strong> We shall have no
                  liability for any failure or delay resulting from any abnormal
                  or unforeseeable circumstances outside our reasonable control,
                  the consequences of which would have been unavoidable despite
                  all efforts to the contrary, including without limitation
                  governmental action or acts of terrorism, war, earthquake,
                  fire, flood, or other acts of God, labor conditions, delays or
                  failures caused by problems with another system or network,
                  mechanical breakdown or data-processing failures or where we
                  are bound by other legal obligations.
                </p>
                <p>
                  <strong>15.4. Survival.</strong> Upon termination of your
                  account or this Agreement for any other reason, all rights and
                  obligations of the parties that by their nature are continuing
                  will survive such termination.
                </p>
                <p>
                  <strong>15.5. Third Party Rights</strong>. The Terms are not
                  intended and shall not be construed to create any rights or
                  remedies in any parties other than you and us and any
                  affiliates which each shall be a third party beneficiary of
                  the Terms, and no other person shall assert any rights as a
                  third party beneficiary hereunder.
                </p>
                <p>
                  <strong>
                    LICENSEE ACKNOWLEDGES HAVING RECEIVED, READ AND UNDERSTOOD
                    THE FOREGOING TERMS AND HEREBY AGREES TO BE BOUND BY ALL OF
                    THE TERMS AND CONDITIONS HEREOF.
                  </strong>
                </p>
                <p>
                  <em>v1.0</em>
                </p>
              </div>
            </section>
          </article>
        </Container>
      </ScMain>
    </Layout>
  );
};

export default LegalPage;

export const Head: HeadFC = () => <title>API License Agreement</title>;
